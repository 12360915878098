import React from "react"
import Helmet from "react-helmet"
import { withPrefix } from "gatsby"

export default function Chat() {
  return (
    <>
      <link
        href="https://static.convaise.com/popup/latest/popup-v4.min.css?v=1.0.0"
        rel="stylesheet"
      ></link>
      <div
        id="convaise-wrapper"
        className="convaisecss-clear"
        data-name="Trusty"
        // data-gdpr-mode="navbar"
        // data-gdpr-url="https://www.bonn.de/datenschutz.php"
        data-persist-chat="true"
        data-show-name-button="true"
        data-news="false"
        data-botid="ddxnh42kl4cvu9n1"
        data-src="https://bots.convaise.com/regioit/paderborn-iframe-test/"
        data-avatar-url="https://static.convaise.com/avatars/avatarPerson.svg?v=1.0.0"
      />
      <Helmet>
        <script src={withPrefix("popup-v4.js")} type="text/javascript" />
      </Helmet>
    </>
  )
}
